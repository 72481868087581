import { Stack } from "@fluentui/react";
import styles from "../Answer.module.css";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../hooks/useStore";
import {  RuleSearch } from "../../../api/models";
import DOMPurify from "dompurify";

interface Props {
    isChatPage?: boolean
}

interface RoleOption {
    label: string;
    className: string;
    isActive: boolean;
    onClick: () => void;
    showIf: boolean;
    child?: React.ReactNode; // Make 'child' optional
}

export const RuleSearchChat = observer(({ isChatPage = false }: Props) => {
    const {
        rootStore: { sessionChatsStore },
    } = useStore();

    // style
    const itemChoosenRole = !isChatPage ? styles.itemChoosenRole : styles.itemChoosenRoleTop
    const boxChoosenRole = !isChatPage ? styles.boxChoosenRole : styles.boxChoosenRoleTop

    const roleOptions: RoleOption[] = [
        {
            label: `Tri thức<br />ngành`,
            className: itemChoosenRole,
            isActive: sessionChatsStore.ruleSearch === RuleSearch.knowledge,
            onClick: () => onclickRuleSearch(RuleSearch.knowledge),
            showIf: true
        },
        {
            label: `Nội dung<br />tham khảo`,
            className: itemChoosenRole,
            isActive: sessionChatsStore.ruleSearch === RuleSearch.reference,
            onClick: () => onclickRuleSearch(RuleSearch.reference),
            showIf: true
        },
    ];

    const onclickRuleSearch = (rule: string) => {
        sessionChatsStore.SET_RULE_SEARCH(rule)
    }

    return (
        <>
            <Stack>
                {
                    !isChatPage &&
                    <Stack.Item className={styles.initSefarating}>
                        <div className={styles.titleChoosen}>Chọn chủ đề</div>
                    </Stack.Item>
                }
                <Stack.Item className={boxChoosenRole}>
                    <div className={`${styles.itemsChoosenRole}`}>

                        {roleOptions.map((option) => (
                            option.showIf && (
                                <div
                                    key={option.label}
                                    className={`${option.className} ${option?.child && option.isActive ? styles.itemRoleVdnActive : option.isActive ? styles.itemChoosenRoleActive : ''}`}
                                    onClick={option.onClick}
                                >
                                    <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(option.label) }}></span>
                                    {option?.child ?? null}
                                </div>
                            )
                        ))}

                    </div>
                </Stack.Item>
            </Stack>
        </>

    );
});
